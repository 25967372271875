import BirdiSelectSearchByDrugMobileImg from 'assets/images/birdi-select-info-banner-search-by-drugs-mobile.png';
import BirdiSelectSearchByDrugImg from 'assets/images/birdi-select-info-banner-search-by-drugs.png';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { capitalize } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropdownComponent from 'ui-kit-v2/dropdown/dropdown';
import { DropdownOption } from 'ui-kit-v2/dropdown/dropdown.props';
import Icon from 'ui-kit-v2/icon/icon';

import Button from 'ui-kit/button/button';
import MentalHealthIcon from 'ui-kit/icons/mental-health/mental-health-icon';
import MustacheIcon from 'ui-kit/icons/mustache/mustache-icon';
import RxRefillsIcon from 'ui-kit/icons/rx-refills/rx-refills-icon';
import SkinCareIcon from 'ui-kit/icons/skin-care-icon/skin-care-icon';
import UrgentCareIcon from 'ui-kit/icons/throat/trhoat-icon';
import WomenBodyIcon from 'ui-kit/icons/women-body/women-body';

import { Icon as IconList } from 'display-components/icon-list/icon-list';
import MedicineCard from 'display-components/medicine-card/medicine-card';

import { resetAutocompleteValue } from 'state/discount-card/discount-card.reducers';
import { getAutocompleteValues } from 'state/discount-card/discount-card.routines';
import {
    discountCardScriptSaveAutocompleteValuesSelector,
    discountCardScriptSaveIsAutocompleteLoadingSelector
} from 'state/discount-card/discount-card.selector';

import { BREAKPOINTS } from 'const/breakpoints';

import { MedicinesCardsMock } from 'util/birdi-select-conditions.mock';

import useWindowDimensions from 'hooks/useWindowDimensions';

import './birdi-select-search-by-drug.style.scss';

export const BirdiSelectSearchByDrug = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const [background, setBackground] = useState<string>(BirdiSelectSearchByDrugImg);
    const [searchValue, setSearchValue] = useState<string>('');
    const [options, setOptions] = useState<DropdownOption[]>([]);
    const autocompleteValues = useSelector(discountCardScriptSaveAutocompleteValuesSelector);
    const isLoading = useSelector(discountCardScriptSaveIsAutocompleteLoadingSelector);

    const randomIcons: IconList[] = [
        {
            id: 5,
            icon: React.createElement(SkinCareIcon),
            title: 'Skin Care'
        },
        {
            id: 6,
            icon: React.createElement(MentalHealthIcon),
            title: 'Mental Health'
        },
        {
            id: 3,
            icon: React.createElement(MustacheIcon),
            title: 'Mens Health'
        },
        {
            id: 4,
            icon: React.createElement(RxRefillsIcon),
            title: 'Rx Refills'
        },
        {
            id: 1,
            icon: React.createElement(WomenBodyIcon),
            title: "Women's Health"
        },
        {
            id: 2,
            icon: React.createElement(UrgentCareIcon),
            title: 'Urgent Care'
        }
    ];

    const medicines = MedicinesCardsMock.map((item) => ({
        ...item,
        icon: randomIcons[Math.floor(Math.random() * randomIcons.length)]
    }));

    /**
     * TBD
     * Once the new API is implemented, I believe we can remove this search
     * this type of request consumes credits in the scriptSave API
     * @param searchValue
     */
    const handleSearch = (searchValue: string) => {
        setSearchValue(searchValue);
        if (searchValue.length >= 3) {
            dispatch(
                getAutocompleteValues.trigger({
                    search: searchValue
                })
            );
        }
    };

    const handleClear = () => {
        setSearchValue('');
        // reset scriptSave autocomplete values
        dispatch(resetAutocompleteValue());
    };

    const handleSelectedValue = (selectedValue: string) => {
        setSearchValue(selectedValue);
    };

    const parseIntoOptions = useCallback(() => {
        if (!searchValue) return [];
        return autocompleteValues.map((item) => ({
            value: item,
            label: (
                <div className="birdi-select-search-by-drug__search_box__dropdown__options">
                    <Icon width="18" height="16" viewBox="0 0 16 16" icon="pill" /> {item}
                </div>
            )
        })) as DropdownOption[];
    }, [autocompleteValues, searchValue]);

    useEffect(() => {
        setOptions(parseIntoOptions());
    }, [autocompleteValues, parseIntoOptions, searchValue]);

    useEffect(() => {
        if (width <= BREAKPOINTS.md) {
            setBackground(BirdiSelectSearchByDrugMobileImg);
        } else {
            setBackground(BirdiSelectSearchByDrugImg);
        }
    }, [width]);

    return (
        <div className="birdi-select-search-by-drug">
            <div
                className="birdi-select-search-by-drug__container"
                style={{
                    backgroundImage: `url(${background})`,
                    backgroundPosition: background === BirdiSelectSearchByDrugImg ? 'right center' : 'right top',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'auto 10rem'
                }}
            >
                <div className="birdi-select-search-by-drug__search_box">
                    <div className="birdi-select-search-by-drug__search_box__title">
                        <h2>{t('pages.birdiSelect.searchByDrug.exploreBirdiFormulary')}</h2>
                        <h3>{t('pages.birdiSelect.searchByDrug.overDescription')}</h3>
                    </div>

                    <DropdownComponent
                        className="birdi-select-search-by-drug__search_box__dropdown"
                        contentRight={{
                            children: (
                                <div className="birdi-select-search-by-drug__search_box__dropdown__contentRight">
                                    <Button
                                        onClick={() => handleSearch(searchValue)}
                                        searchIcon={!isLoading}
                                        isBusy={isLoading}
                                        async={isLoading}
                                        type="button"
                                        variant="primary"
                                        label="Search"
                                    />
                                </div>
                            )
                        }}
                        label={t('pages.birdiSelect.searchByDrug.label')}
                        canSearch
                        options={options}
                        onSearch={(searchValue: string) => handleSearch(searchValue)}
                        onSelect={(selectedOption) => handleSelectedValue(selectedOption)}
                        onClear={handleClear}
                    />
                </div>
            </div>
            {searchValue && searchValue.length >= 3 && !isLoading && (
                <>
                    <div className="birdi-select-search-by-drug__search-results">
                        <p>
                            {t('pages.birdiSelect.searchByDrug.searchResultsFor')} <strong>{searchValue}</strong>
                        </p>
                    </div>
                    <div className="birdi-select-search-by-drug__search_box__results">
                        {(() => {
                            const filteredMedicines = medicines.filter((medicine) =>
                                medicine.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                            );

                            return filteredMedicines.length === 0 ? (
                                <div className="birdi-select-search-by-drug__search_box__no_results">
                                    <Icon icon="big-search" width="112" height="112" viewBox="0 0 112 112" />
                                    <h3>No results found for '{searchValue}'</h3>
                                    <h4>Please check spelling and try again</h4>
                                </div>
                            ) : (
                                filteredMedicines.map((item, index) => (
                                    <MedicineCard
                                        key={index}
                                        name={capitalize(item.name)}
                                        description={item.description}
                                        dose={item.dose}
                                        gpi={item.gpi}
                                        ndc={item.ndc}
                                        form={item.form}
                                        variation={width <= BREAKPOINTS.md ? 'list' : undefined}
                                    />
                                ))
                            );
                        })()}
                    </div>
                </>
            )}
        </div>
    );
};
